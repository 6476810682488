import * as React from "react"
// import { Link } from "gatsby"

import Layout from "../components/layout"
import Seo from "../components/seo"
// React Bootstrap
import { Container, Row, Col } from "react-bootstrap"

import { StaticImage } from "gatsby-plugin-image"

import Consultation from "../components/free-consultation"

const SecondPage = () => (
  <Layout>
    <Seo title="Planning and Design Services" />
    <Container className="singleCol">
    <h1 className="text-center mb-4">Planning and Design Services</h1>
    <p>Every successful home remodel starts with a good plan. I listen very carefully to establish goals and then show two to five alternatives. Considerations include which modifications are most likely to increase a home’s value.</p>
    </Container>
    <Container className="text-center">
      <Row>
      <Col lg={4}>
        <p>
          <StaticImage 
            src="../images/process/preliminary-project-plan-first-floor.jpg"
            alt="Preliminary Project Plan - First Floor Remodel"
            placeholder="blurred"
          />
        </p>
        <p><small>Preliminary Project Plan - First Floor Remodel</small></p>
      </Col>
      <Col lg={4}>
        <p>
          <StaticImage 
            src="../images/process/preliminary-project-plan-living-room.png"
            alt="Preliminary Project Plan - Living Room, With Furniture"
            placeholder="blurred"
          />
        </p>
        <p><small>Preliminary Project Plan - Living Room, With Furniture</small></p>
      </Col>
      <Col lg={4}>
        <p>
          <StaticImage 
            src="../images/process/preliminary-project-plan-1-bed-condo.jpg"
            alt="Preliminary Project Plan - 1 Bedroom Condo"
            placeholder="blurred"
          />
        </p>
        <p><small>Preliminary Project Plan - 1 Bedroom Condo</small></p>
      </Col>
      </Row>
      <hr />
    </Container>
    <Container className="singleCol">
      <h2>Master Project Plan</h2>
      <p>Alternatives are evaluated and the best ideas my clients like best are consolidated into a <strong>Master Plan</strong>, which is suitable for review with local City Planning, Building, and Fire Departments. Then the plan is used to seek prices from one or more general contractors. If the Master Plan is too expensive or disruptive to be undertaken at one time, we will recommend multiple phases of work. Design costs vary depending on project complexity and will be provided to the owner</p>
      <hr />
      <h2>Design Review Process</h2>
      <p>If the community requires a <strong>public hearing</strong> for exterior design modifications, the Architect will help you meet with neighbors and City Planning, prepare exhibits for meetings, and attend the public hearings.</p>
      <hr />
      <h2>Project Implementation</h2>
      <p>Once the plan is approved, the Architect usually completes structural, heating, plumbing, and electrical plans himself, but he will recruit needed experts including interior designers if desired. Complete plans are necessary for efficiently obtaining a building permit and for construction with reliable prices and minimal surprises. During construction the Architect is often asked <strong>questions by the contractor and/or the building inspector.</strong></p>
    </Container>
    <Container className="text-center">
      <p>
        <StaticImage 
          src="../images/process/307-tg-exterior1_orig.jpg"
          alt="Exterior 3D model of house"
          placeholder="blurred"
        />
      </p>
      <p><small>Final City Design Review Plan</small></p>
    <hr />
    </Container>
    <div className="mt-3">
      <Consultation  />
    </div>
  </Layout>
)

export default SecondPage
